import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const jwtToken = localStorage.getItem("a_p_user_token")
const userdata = localStorage.getItem("userData")
export const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: userdata ? JSON.parse(userdata) : {},
    token:jwtToken ? jwtToken : ""
  },
  reducers: {
    handleLogin: (state, action) => {
      localStorage.setItem("userData", JSON.stringify(action.payload[0]));
      localStorage.setItem("a_p_user_token", action.payload[0].jwttoken);
      state.userData = action.payload[0];
      window.location.href = "home"

    },
    handleLogout: (state) => {
      state.userData = {};
      localStorage.removeItem("userData");
      localStorage.removeItem("a_p_user_token");
      window.location.href = `${window.location.origin }/login`
    },
  },
});

export const { handleLogin, handleLogout } = userSlice.actions;

export default userSlice.reducer